<template>
  <!--begin::Card-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            data-kt-subscription-table-filter="search"
            class="form-control form-control-solid w-250px ps-14"
            placeholder="Search Subscriptions"
            v-model="searchSubscription"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->

      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div
          class="d-flex justify-content-end"
          data-kt-subscription-table-toolbar="base"
        >
          <!--begin::Export-->
          <button
            type="button"
            class="btn btn-light-primary me-3"
            data-bs-toggle="modal"
            data-bs-target="#kt_subscriptions_export_modal"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button>
          <!--end::Export-->

          <!--begin::Add subscription-->
          <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal_new_subscription"
              @click="
            this.updateSubscriptionMode = false;
            this.selectedSubscription = {};
          "

          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr075.svg" />
            </span>
            Add Subscription
          </a>
          <!--end::Add subscription-->
        </div>
        <!--end::Toolbar-->

        <!--begin::Group actions-->
        <div
          class="d-flex justify-content-end align-items-center d-none"
          data-kt-subscription-table-toolbar="selected"
        >
          <div class="fw-bolder me-5">
            <span
              class="me-2"
              data-kt-subscription-table-select="selected_count"
            ></span>
            Selected
          </div>

          <button
            type="button"
            class="btn btn-danger"
            data-kt-subscription-table-select="delete_selected"
          >
            Delete Selected
          </button>
        </div>
        <!--end::Group actions-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0">
      <KTDatatable
        :table-data="data"
        :table-header="table.columns"
        :current-page="table.currentPage"
        :total="total"
        :rows-per-page="table.perPage"
        :sort-lable="id"
        :loading="loading"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-checkbox="{ row: subscription }">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="subscription.id"
            />
          </div>
        </template>
        <template v-slot:cell-user_name="{ row: subscription }">
          {{ subscription.user ? subscription.user.name : "" }}
          <!--          <router-link-->
          <!--            to="/subscriptions/view-subscription"-->
          <!--            href=""-->
          <!--            class="text-gray-800 text-hover-primary mb-1"-->
          <!--          >-->
          <!--            {{ subscription.user.name }}-->
          <!--          </router-link>-->
        </template>
        <template v-slot:cell-user_email="{ row: subscription }">
          <div
            data-bs-toggle="tooltip"
            :title="subscription.user ? subscription.user.email : ''"
          >
            {{
              subscription.user
                ? subscription.user.email
                  ? subscription.user.email.slice(0, 25)
                  : ""
                : ""
            }}
          </div>
        </template>
        <template v-slot:cell-status="{ row: subscription }">
          <a href="javascript:" class="text-gray-600 text-hover-primary mb-1">
            <div
              :class="`badge badge-light-${
                subscription.is_active ? 'success' : 'danger'
              }`"
            >
              <span>{{ subscription.is_active ? "Active" : "InActive" }}</span>
            </div>
          </a>
        </template>
        <template v-slot:cell-amount="{ row: subscription }">
          <div class="badge badge-light">{{ subscription.amount }}</div>
        </template>
        <template v-slot:cell-plan="{ row: subscription }">
          <div class="badge badge-light">
            {{ subscription.plan ? subscription.plan.product_id : "" }}
          </div>
        </template>
        <template v-slot:cell-type="{ row: subscription }">
          {{ subscription.type }}
        </template>
        <template v-slot:cell-transaction_id="{ row: subscription }">
          {{ subscription.transaction_id }}
        </template>
        <template v-slot:cell-coupon="{ row: subscription }">
          <div class="badge badge-light">{{ subscription.coupon_code }}</div>
        </template>
        <template v-slot:cell-created_at="{ row: subscription }">
          {{ new Date(subscription.created_at).toLocaleString() }}
        </template>
        <template v-slot:cell-plan_expiration_date="{ row: subscription }">
          {{ new Date(subscription.plan_expiration_date).toLocaleString() }}
        </template>

        <template v-slot:cell-actions="{ row: subscription }">
          <a
            href="#"
            class="btn btn-sm btn-light btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
            >Actions
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="media/icons/duotune/arrows/arr072.svg" />
            </span>
          </a>
          <!--begin::Menu-->
          <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <a
                  href="javascript:"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_new_subscription"
                  @click="editSubscriptionClicked(subscription)"
                  class="menu-link px-3">
                Edit
              </a>

            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
<!--            <div class="menu-item px-3">-->
<!--              <a @click="deleteCustomer(subscription)" class="menu-link px-3">-->
<!--                Delete-->
<!--              </a>-->
<!--            </div>-->
            <!--end::Menu item-->
          </div>
          <!--end::Menu-->
        </template>
      </KTDatatable>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->

  <AddSubscriptionModal
      :plans="this.plans"
      :selectedSubscription="selectedSubscription"
      :updateMode="updateSubscriptionMode"
      @subscription-saved="subscriptionSaved"
  ></AddSubscriptionModal>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
// import NewCouponModal from "../components/modals/forms/NewCouponModal.vue";
import ApiService from "../core/services/ApiService.ts";
import Swal from "sweetalert2/dist/sweetalert2.js";

// import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";

import RemoteTable from "@/components/RemoteTable.vue";
import useDebounceRef from "../composables/useDebounceRef";
import {MenuComponent} from "@/assets/ts/components";
import AddSubscriptionModal from "@/components/modals/forms/AddSubscriptionModal.vue";
import {ElSelect} from "element-plus";

export default {
  name: "Users",
  components: {AddSubscriptionModal, KTDatatable },
  setup() {
    let subscription = [];
    const total = ref(0);
    const table = reactive({
      height: 411,
      data: [],
      columns: [
        { key: "checkbox", sortable: true, sortingField: "id" },
        {
          key: "user_name",
          name: "Name",
          sortable: false,
        },
        {
          key: "user_email",
          name: "Email",
          sortable: false,
        },
        {
          key: "status",
          name: "Status",
          sortable: false,
        },
        {
          key: "amount",
          name: "Payment",
          sortable: true,
        },
        {
          key: "plan",
          name: "Plan",
          sortable: true,
          sortingField: "plan_id",
        },
        {
          key: "type",
          name: "Type",
          sortable: true,
        },
        {
          key: "transaction_id",
          name: "Transaction ID",
          sortable: true,
        },
        {
          key: "coupon",
          name: "Coupon",
          sortable: true,
        },
        {
          key: "created_at",
          name: "Subscribe Date",
          sortable: true,
        },
        {
          key: "plan_expiration_date",
          name: "Expire Date",
          sortable: true,
        },
        {
          key: "actions",
          name: "Actions",
          sortable: false,
        },
      ],
      remote: {
        url: "admin/subscriptions",
        method: "GET",
      },
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      perPage: 10,
      sort: { columnName: "id", order: "desc" },
    });
    const data = ref(subscription);
    const loading = ref(false);
    const loadSubscriptions = () => {
      loading.value = true;
      ApiService.get(table.remote.url, null, {
        params: {
          page: table.currentPage,
          perPage: table.perPage,
          search: searchSubscription.value,
          sort: table.sort,
        },
      })
        .then(({ data: dataC }) => {
          console.log("datadatadatadatadatadatadatadata");
          console.log(dataC.data);
          data.value = dataC.data;
          table.data = dataC.data;
          total.value = dataC.meta.total;
          console.log("total");
          console.log(total);
          console.log("total");
        })
        .catch((err) => {
          console.log("errerrerrerrerrerr");
          console.log(err);
          console.log("errerrerrerrerrerr");
        })
        .finally(() => {
          loading.value = false;
          MenuComponent.reinitialization();
        });
    };
    const plans = ref([]);
    const getPlans = () => {
      ApiService.get("admin/plans", "", {
        params: {
          all: true,
        },
      }).then(({ data }) => {
        plans.value = data;
      });
    }

    let searchSubscription = useDebounceRef("", 400);

    watch(searchSubscription, (newQuery) => {
      loadSubscriptions();
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Subscription List", ["Apps", "Subscriptions"]);
      getPlans();
      loadSubscriptions();
    });
    return {
      data,
      table,
      total,
      loading,
      loadSubscriptions,
      searchSubscription,
      getPlans,
      plans
    };
  },
  // mounted() {
  //   this.getPlans();
  //   this.loadSubscriptions();
  // },
  data: function () {
    return {
      updateSubscriptionMode: false,
      selectedSubscription: {}
    };
  },
  methods: {
    subscriptionSaved(subscription) {
      if (!this.updateSubscriptionMode) {
        this.table.data.push(subscription);
      } else {
        Object.assign(this.currentSubscriotion, subscription);
      }
    },
    handleSizeChange(perPage) {
      this.table.perPage = perPage;
      this.loadSubscriptions();
    },
    handleSortChange(sort) {
      console.log(sort);
      this.table.sort = sort;
      this.loadSubscriptions();
    },
    handleCurrentChange(page) {
      this.table.currentPage = page;
      this.loadSubscriptions();
      console.log("page");
      console.log(page);
      console.log("page");
    },
    deleteCoupon(coupon) {
      ApiService.delete(`admin/coupons/${coupon.id}`).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            text: data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            timer: 2000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.loadSubscriptions();
        } else {
          Swal.fire({
            text: data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    getPercentage(item) {
      return ((100 * item.uses) / item.max_uses).toFixed(2);
    },
    getColor(item) {
      const percentage = this.getPercentage(item);
      if (percentage <= 30) return "primary";
      if (percentage <= 60) return "success";
      if (percentage <= 90) return "warning";
      return "danger";
    },
    editSubscriptionClicked(subscription) {
      this.updateSubscriptionMode = true;

      this.selectedSubscription = { ...subscription };
      this.selectedSubscription.user_email = subscription.user.email;
      this.currentSubscriotion = subscription;
      console.log("this.currentSubscriotion");
      console.log(this.currentSubscriotion);
      console.log("this.currentSubscriotion");
    },
  },
};
</script>
