
import { computed, defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { array } from "yup";
import moment, {now} from "moment";
import {MenuComponent} from "@/assets/ts/components";
import {ElSelect} from "element-plus";

interface SubscriptionData {
  id: number;
  user_email: string;
  plan_id: number;
  transaction_id: string;
  plan_expiration_date: string;
  type: string;
  amount: number;
  coupon_code: string;
  status: number;
}

export default defineComponent({
  name: "add-subscription-modal",
  props: ["plans", "selectedSubscription", "updateMode"],
  emits: ["subscriptionSaved"],
  components: {},
  setup(props, context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const closeRef = ref<null | HTMLButtonElement>(null);

    console.log(props.selectedSubscription);
    const defaultData = {
      id: 0,
      user_email: "",
      plan_id: 0,
      transaction_id: "",
      plan_expiration_date: "",
      type: "",
      amount: 0,
      coupon_code: "",
      status: 1,
      };
    const targetData = ref<SubscriptionData>({ ...defaultData });
    const subscription = computed(() => props.selectedSubscription || targetData);
    console.log("subscription.value");
    console.log(subscription.value);
    console.log("subscription.value");
    console.log("subscription.value");
    const rules = ref({
      user_email: [
        {
          required: true,
          message: "Please select User",
          trigger: "blur",
        },
      ],
      plan_id: [
        {
          required: true,
          message: "Please select plan",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "Please select type",
          trigger: "change",
        },
      ],
      plan_expiration_date: [
        {
          required: true,
          message: "Please select Expire Date",
          trigger: "change",
        },
      ],
    });

    const usersResult = ref<any>([]);
    const usersLoading = ref(false)

    const searchUser = (query) => {
      usersLoading.value = true;
      ApiService.get('admin/users', "", {
        params: {
          search: query
        }
      }).then(({data})=>{
        usersResult.value = data.data;
      }).finally(()=>{
        usersLoading.value = false;
      });
    };

    const submit = () => {
      // console.log(targetData.value);
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let service;
          let message;
          if (subscription.value.id) {
            service = ApiService.put(
              `admin/subscriptions/${subscription.value.id}`,
                subscription.value
            );
            message = "Subscription updated successfully!";
          } else {
            service = ApiService.post("admin/subscriptions", subscription.value);
            message = "Subscription created successfully!";
          }
          return service
            .then(({ data }) => {
              if(!data.success) message = 'Error while saving subscription'
              Swal.fire({
                text: message,
                icon: data.success? "success" : 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              if(data.success) {
                context.emit("subscriptionSaved", data);
                closeRef?.value?.click();
                formRef?.value?.resetFields();
                targetData.value = {...defaultData};
              }
              return data;
            })
            .catch((err) => {
              const response = err.response.data;
              const message = `
                        <strong>${response.message}</strong>
                        <ul>
                            ${Object.values(response.errors).map(
                              (error) => `<li>${error}</li>`
                            )}
                        </ul>`;
              console.log(message);
              Swal.fire({
                html: message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .finally(() => {
              loading.value = false;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      submit,
      loading,
      formRef,
      closeRef,
      rules,
      subscription,
      searchUser,
      usersResult,
      usersLoading
    };
  },
});
